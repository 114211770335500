import React from 'react'
import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { ApolloProvider as BaseApolloProvider } from '@apollo/react-hooks'

export const APOLLO_CLIENT_INSTANCE = new ApolloClient({
	uri: process.env.GATSBY_GRAPHQL_GEN_HOST,
	fetch,
})

export const ApolloProvider = ({ children }) => (
	<BaseApolloProvider client={APOLLO_CLIENT_INSTANCE}>{children}</BaseApolloProvider>
)
