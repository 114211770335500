import { BDCLogo, Facebook, LinkedIn, Twitter, Instagram, Pinterest } from '@jelly/components'

export const HUB_PRESENTED_BY_LOGO = {
	Svg: BDCLogo,
	color: 'navy',
	height: '20',
	width: '175',
}

export const SOCIAL_MEDIA_GROUP_ICONS = [
	{ Svg: Facebook, label: 'Visit us on Facebook', slug: 'https://www.facebook.com/businessdotcom/' },
	{ Svg: LinkedIn, label: 'Visit us on LinkedIn', slug: 'https://www.linkedin.com/company/business-com-/' },
	{ Svg: Twitter, label: 'Visit us on Twitter', slug: 'https://twitter.com/businessdotcom/' },
	{ Svg: Instagram, label: 'Visit us on Instagram', slug: 'https://www.instagram.com/business_dotcom/' },
	{ Svg: Pinterest, label: 'Visit us on Pinterest', slug: 'https://www.pinterest.com/businessdotcom/' },
]

export {
	BlockMonogram,
	EmailSignUpMessagingBoxBdc as EmailSignUpMessagingBox,
	FloaterBarContextBdc as FloaterBarContext,
	FooterLogoBdc as FooterLogo,
	HeaderLogoBdc as HeaderLogo,
	BlockBDCWhite as SiteLogo,
	NavigationLinksBdc as NavigationLinks,
} from '@jelly/components'
