export const IS_GATSBY_SITE = true
export const SITE_HOST = process.env.GATSBY_SITE_HOST || 'https://www.business.com'
export const EMAIL_API = process.env.GATSBY_API_EMAIL || 'https://staging.business.com/api/email/sign-up/'

export const META_DATA = {
	applicationName: 'business.com',
	canonical: '/',
	description: 'Get the business advice, tips, and resources you need to run and grow your business.',
	ogImage: 'https://www.business.com/images/branding/logos/block_monogram_navy.jpg',
	robots: null,
	title: 'business.com: Expert Business Advice, Tips, and Resources',
	twitterName: '@businessdotcom',
}

export const AUTHOR_LINK_ATTRIBUTES = {
	href: `/advice/member/p/`,
	target: '_self',
}
export const ARTICLE_SCHEMA_PROPS = {
	name: 'business.com',
	logoUrl: 'https://www.business.com/images/branding/logos/block_monogram_navy.jpg',
	logoWidth: '400',
	logoHeight: '400',
}
export const LINK_BUILDING_HERO = 'https://www.business.com/images/link-build-template-hero.jpg'

export const EMAIL_CAPTURE_SOURCES = {
	article: 'BDC_email_articlepage',
	homepage: 'BDC_email_home',
	hub: 'BDC_email_hubpage',
	subscribeButton: 'BDC_email_siteheader',
}

export const HUB_LINKS = [
	{
		isHighlighted: true,
		title: 'COVID-19 Resources',
		slug: 'covid-resources/',
		featuredTopics: [
			{
				title: 'Your Guide to Using Non-Contact Infrared Thermometers at Work',
				path: '/articles/non-contact-infrared-thermometers/',
			},
			{
				title: 'How to Transition to Long-Term Work From Home',
				path: '/articles/transitioning-to-long-term-work-from-home/',
			},
			{
				title: 'What Type of Employee Monitoring System Do You Need?',
				path: '/articles/types-of-employee-monitoring-to-consider-for-your-business/',
			},
			{
				title: 'The Best Remote PC Access Software of 2022',
				path: '/categories/best-remote-pc-access-software/',
			},
			{
				title: 'The Best Employee Monitoring Software of 2022',
				path: '/categories/employee-monitoring-software/',
			},
		],
	},
	{
		title: 'Finance',
		slug: 'finance/',
		subHubs: [
			{ title: 'Accounting', slug: 'accounting/' },
			{ title: 'Finances', slug: 'finances/' },
			{ title: 'Financial Solutions', slug: 'financial-solutions/' },
			{ title: 'Funding', slug: 'funding/' },
		],
		featuredTopics: [
			{
				title: 'The Ultimate Guide on How to Get a Business Loan.',
				path: '/articles/how-to-get-a-business-loan-ultimate-guide/',
			},
			{ title: 'Top Small Business Grants of 2022', path: '/articles/small-business-grants/' },
			{
				title: 'How to Create a Business Budget, With Free Budget Template',
				path: '/articles/small-business-budget-templates/',
			},
			{
				title: 'The Best Business Loan and Financing Options of 2022',
				path: '/categories/small-business-loans-and-financing-options/',
			},
			{ title: 'The Best Accounting and Invoice-Generating Software', path: '/categories/accounting-software/' },
		],
	},
	{
		title: 'Human Resources',
		slug: 'human-resources/',
		subHubs: [
			{ title: 'Employees', slug: 'employees/' },
			{ title: 'HR Solutions', slug: 'hr-solutions/' },
			{ title: 'Hiring', slug: 'hiring/' },
			{ title: 'Managing', slug: 'managing/' },
		],
		featuredTopics: [
			{
				title: 'Health Insurance: Employer and Employee Costs in 2022',
				path: '/articles/health-insurance-costs-this-year/',
			},
			{ title: "PEO vs. ASO: What's Best for Your Business?", path: '/articles/peo-vs-aso/' },
			{ title: 'The Best Online Payroll Services of 2022', path: '/categories/online-payroll-services/' },
			{
				title: 'The Best Time and Attendance Services and Software 2022',
				path: '/categories/time-and-attendance-software/',
			},
		],
	},
	{
		title: 'Marketing',
		slug: 'marketing/',
		subHubs: [
			{ title: 'Branding', slug: 'branding/' },
			{ title: 'Business Intelligence', slug: 'business-intelligence/' },
			{ title: 'Customers', slug: 'customers/' },
			{ title: 'Marketing Solutions', slug: 'marketing-solutions/' },
			{ title: 'Marketing Strategy', slug: 'marketing-strategy/' },
			{ title: 'Public Relations', slug: 'public-relations/' },
			{ title: 'Sales', slug: 'sales/' },
			{ title: 'Social Media', slug: 'social-media/' },
		],
		featuredTopics: [
			{ title: 'Guide to Email Marketing', path: '/articles/guide-to-email-marketing/' },
			{ title: 'How to Conduct a Marketing Analysis', path: '/articles/how-to-conduct-a-marketing-analysis/' },
			{
				title: 'Step-by-Step Guide to Social Media Marketing for Small Businesses',
				path: '/articles/social-media-marketing-guide/',
			},
			{
				title: 'The Best Text Message Marketing Services of 2022',
				path: '/categories/best-text-message-marketing-services/',
			},
			{ title: 'The Best CRM Software of 2022', path: '/categories/crm-software/' },
		],
	},
	{
		title: 'Technology',
		slug: 'technology/',
		subHubs: [
			{ title: 'Computers', slug: 'computers/' },
			{ title: 'Mobile', slug: 'mobile/' },
			{ title: 'Networking', slug: 'networking/' },
			{ title: 'Security', slug: 'security/' },
			{ title: 'Software', slug: 'software/' },
			{ title: 'Storage', slug: 'storage/' },
			{ title: 'Technology Solutions', slug: 'technology-solutions/' },
		],
		featuredTopics: [
			{ title: '16 Questions to Ask Your Web Developer', path: '/articles/questions-to-ask-web-developer/' },
			{
				title: 'Mobile Wallet Guide: Google Pay vs. Apple Pay vs. Samsung Pay',
				path: '/articles/google-pay-vs-apple-pay-vs-samsung-pay/',
			},
			{
				title: "The Small Business Owner's Guide to Data Analytics",
				path: '/articles/data-analysis-for-small-business/',
			},
			{ title: 'The Best Business Phone Systems of 2022', path: '/categories/business-phone-systems/' },
			{
				title: 'The Best Remote PC Access Software of 2022',
				path: '/categories/best-remote-pc-access-software/',
			},
		],
	},
	{
		title: 'Business Basics',
		slug: 'business-basics/',
		subHubs: [
			{ title: 'Business Solutions', slug: 'business-solutions/' },
			{ title: 'Entrepreneurship', slug: 'entrepreneurship/' },
			{ title: 'Franchising', slug: 'franchising/' },
			{ title: 'Industry', slug: 'industry/' },
			{ title: 'Insurance', slug: 'insurance/' },
			{ title: 'Legal', slug: 'legal/' },
			{ title: 'Starting a Business', slug: 'starting-a-business/' },
			{ title: 'Strategy', slug: 'strategy/' },
		],
		featuredTopics: [
			{ title: '5 Effective Ways to Beat Your Competition', path: '/articles/5-ways-to-beat-competition/' },
			{
				title: '5 Simple Steps to Valuing Your Small Business',
				path: '/articles/four-simple-steps-to-valuing-your-small-business/',
			},
			{ title: 'Writing a Business Growth Plan', path: '/articles/writing-a-business-growth-plan/' },
			{ title: 'The Best Access Control Systems of 2022', path: '/access-control-systems/best/' },
			{ title: 'The Best Call Center Services for 2022', path: '/categories/call-center-services/' },
		],
	},
]

export const HAMBURGER_MENU_CORE_LINKS = [
	{
		title: 'Advertise With Us',
		slug: '/connect/',
		target: '_self',
		icon: 'advertise',
		trackingName: 'Hamburger AdvertiseWithUs link',
	},
]

export const SHOULD_SHOW_ADVERTISE_WITH_US_BUTTON = true

export const SEARCH_TAGLINE = 'Connecting You To Opportunity'

export const ADVERTORIAL_SINGLE_SPONSOR = ['advertorial', 'single-sponsor']

export const ALL_SPONSOR_TYPES = ['hub-sponsor', ...ADVERTORIAL_SINGLE_SPONSOR]

export const LONG_FORM = 'long_form'

export const RECAPTCHA_SITE_KEY = '6LflxcQUAAAAAA1Idk3BkmOTlDXc88h7mFVySfqU'

let RFQ_PUBLISHER_ID = process.env.GATSBY_RFQ_PUBLISHER_ID
let WIDGET_ENV = process.env.GATSBY_WIDGET_ENV || 'STG'

export { WIDGET_ENV, RFQ_PUBLISHER_ID }

// Passing empty string to force default theme
export const RFQ_WIDGET_THEME = ''

export const DFP_CONTENT_ID_TYPE = 'guid'
export const DFP_NETWORK_ID = '21735953238'
export const DFP_NOBID_ID = '21863437039'
export const DFP_SITE_NAME = 'bdc'

export const QT_ADMIN_PUB_ID = '1000'
export const HAS_COMMUNITY = true

export const FOOTER_LINKS = {
	resources: [
		{
			dataType: 'link',
			text: 'Latest Content',
			link: '/content/',
		},
		{
			dataType: 'link',
			text: 'Reviews',
			link: '/buying-guides/',
		},
	],
	ourCompany: [
		{
			dataType: 'link',
			text: 'About Us',
			link: '/about-us/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Advertise With Us',
			link: '/connect/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Privacy Policy',
			link: '/privacy-policy/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Do Not Sell My Personal Information',
			link: '/optout/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Terms of Use',
			link: '/terms-of-use/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Accessibility',
			link: '/accessibility/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Copyright Policy',
			link: '/copyright-policy/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Advertising Disclosure',
			link: '/advertising-disclosure/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Ad Guidelines',
			link: '/ad-guidelines/',
			target: '_self',
		},
	],

	ourBrands: [
		{
			dataType: 'link',
			text: 'Business News Daily',
			link: 'https://www.businessnewsdaily.com',
			target: '_blank',
			rel: 'nofollow',
		},
		{
			dataType: 'link',
			text: 'BuyerZone',
			link: 'https://www.buyerzone.com',
			target: '_blank',
			rel: 'nofollow',
		},
	],
	contactUs: [
		{
			dataType: 'link',
			text: '888.393.5000',
			type: 'phone',
			link: 'tel:1-888-393-5000',
		},
		{
			dataType: 'link',
			text: 'connect@business.com',
			type: 'email',
			link: 'mailto:connect@business.com',
		},
		{
			dataType: 'paragraph',
			type: 'address',
			text: '200 5th Avenue, 2nd Floor',
		},
		{
			dataType: 'paragraph',
			type: 'address',
			text: 'Waltham, MA 02451',
		},
	],
}
